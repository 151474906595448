import { MarketCountryCode } from '@backmarket/http-api'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'

export const KYC_IDS = {
  IDENTITY: 'identity',
  BANK_DETAILS: 'bankDetails',
  BIRTHDATE: 'birthdate',
  IDENTITY_INFORMATIONS: 'identityInformations',
} as const

export const ID_DOCUMENTS = {
  ID_CARD: 'id_verification_id_card',
  PASSPORT: 'id_verification_passeport',
  DRIVING_LICENCE: 'id_verification_driving_licence',
} as const

export const BANKING_DETAILS = {
  iban: 'iban',
  ach: 'ach',
  bacs: 'bacs',
} as const

export const ID_INFORMATIONS = {
  SSN: 'ssn',
  DRIVING_LICENCE: 'driving_license',
} as const

export type KycType = (typeof KYC_IDS)[keyof typeof KYC_IDS]
export type IdDocumentsType = (typeof ID_DOCUMENTS)[keyof typeof ID_DOCUMENTS]
export type IdInformationsType =
  (typeof ID_INFORMATIONS)[keyof typeof ID_INFORMATIONS]
export type BankingDetailsType =
  (typeof BANKING_DETAILS)[keyof typeof BANKING_DETAILS]
export type FieldType =
  | 'iban'
  | 'bic'
  | 'accountNumber'
  | 'routingNumber'
  | 'sortCode'

export interface KYC {
  firstName: boolean
  lastName: boolean
  birthDate: boolean
  phone: boolean
  address: Record<string, boolean>
  postalCodeLength: {
    min: number
    max: number
  }
  [KYC_IDS.IDENTITY]: {
    required: boolean
    list: Array<{
      type: IdDocumentsType
      isOneSide: boolean
    }>
  }
  [KYC_IDS.BANK_DETAILS]: {
    required: boolean
    type: BankingDetailsType
    fields: Array<FieldType>
  }
  [KYC_IDS.BIRTHDATE]: {
    required: boolean
  }
  [KYC_IDS.IDENTITY_INFORMATIONS]?: {
    required: boolean
    list: Array<IdInformationsType>
  }
}
export interface BuybackData {
  iban: boolean
  bacs: boolean
  recyclingFaq: Record<
    'backup' | 'counterOffer' | 'dropOff' | 'google' | 'icloud' | 'laPoste',
    string
  > | null
  tradeinFaq: Record<
    'backup' | 'counterOffer' | 'google' | 'icloud',
    string
  > | null
  csvFaq: string
  kyc: KYC
}

const buybackConfig: Record<string, BuybackData> = {
  [MarketCountryCode.FR]: {
    iban: true,
    bacs: false,
    kyc: {
      firstName: true,
      lastName: true,
      birthDate: true,
      phone: true,
      address: {
        street: true,
        street2: true,
        city: true,
        postalCode: true,
        country: true,
      },
      postalCodeLength: {
        min: 5,
        max: 5,
      },
      identity: {
        required: true,
        list: [
          {
            type: ID_DOCUMENTS.PASSPORT,
            isOneSide: true,
          },
          {
            type: ID_DOCUMENTS.ID_CARD,
            isOneSide: false,
          },
        ],
      },
      bankDetails: {
        required: true,
        type: BANKING_DETAILS.iban,
        fields: ['iban', 'bic'],
      },
      birthdate: {
        required: true,
      },
    },
    recyclingFaq: {
      backup:
        'https://help.backmarket.com/hc/fr-fr/articles/12761011194524-Tout-ce-que-vous-devez-savoir-sur-les-comptes-utilisateurs',
      counterOffer:
        'https://help.backmarket.com/hc/fr-fr/articles/360021663319',
      dropOff: 'https://localiser.laposte.fr/?jesuis=particulier&contact=vente',
      google:
        'https://help.backmarket.com/hc/fr-fr/articles/12761011194524-Tout-ce-que-vous-devez-savoir-sur-les-comptes-utilisateurs#h_01HQ8CHVVX2XZGY60SSXER1VVF',
      icloud:
        'https://help.backmarket.com/hc/fr-fr/articles/12761011194524-Tout-ce-que-vous-devez-savoir-sur-les-comptes-utilisateurs#h_01HQ8E9RC7XFGP96ZSFDZXG7XT',
      laPoste: 'https://localiser.laposte.fr/?jesuis=particulier&contact=boite',
    },
    tradeinFaq: {
      backup:
        'https://help.backmarket.com/hc/fr-fr/articles/360010648979-Comment-sauvegarder-et-supp%5B%E2%80%A6%5D-il-aux-donn%C3%A9es-de-mon-appareil-apr%C3%A8s-sa-revente-',
      icloud:
        'https://help.backmarket.com/hc/fr-fr/articles/360012127479-Comment-d%C3%A9connecter-mon-compte-iCloud-Google-',
      google:
        'https://help.backmarket.com/hc/fr-fr/articles/360012127479-Comment-d%C3%A9connecter-mon-compte-iCloud-Google-',
      counterOffer:
        'https://help.backmarket.com/hc/fr-fr/articles/360021663319',
    },
    csvFaq:
      'https://backmarket.my.site.com/ssc/s/article/Bien-débuter-avec-vos-Annonces',
  },
  [MarketCountryCode.US]: {
    iban: false,
    bacs: false,
    kyc: {
      firstName: true,
      lastName: true,
      birthDate: true,
      phone: true,
      address: {
        street: true,
        street2: true,
        city: true,
        stateOrProvince: true,
        postalCode: true,
        country: true,
      },
      postalCodeLength: {
        min: 5,
        max: 5,
      },
      identity: {
        required: false,
        list: [
          {
            type: ID_DOCUMENTS.ID_CARD,
            isOneSide: false,
          },
          {
            type: ID_DOCUMENTS.DRIVING_LICENCE,
            isOneSide: false,
          },
        ],
      },
      bankDetails: {
        required: true,
        type: BANKING_DETAILS.ach,
        fields: ['accountNumber', 'routingNumber'],
      },
      identityInformations: {
        required: true,
        list: ['driving_license', 'ssn'],
      },
      birthdate: {
        required: true,
      },
    },
    recyclingFaq: {
      backup:
        'https://help.backmarket.com/hc/en-us/articles/12761011194524-Everything-you-need-to-know-about-user-accounts',
      counterOffer:
        'https://help.backmarket.com/hc/fr-fr/articles/360021663319',
      dropOff: 'https://www.ups.com/dropoff/?loc=en_US',
      google:
        'https://help.backmarket.com/hc/en-us/articles/12761011194524-Everything-you-need-to-know-about-user-accounts#h_01HQ8CHVVX2XZGY60SSXER1VVF',
      icloud:
        'https://help.backmarket.com/hc/en-us/articles/12761011194524-Everything-you-need-to-know-about-user-accounts#h_01HQ8E9RC7XFGP96ZSFDZXG7XT',
      laPoste: '',
    },
    tradeinFaq: {
      backup:
        'https://help.backmarket.com/hc/en-us/articles/360010648979-What-happens-to-the-data-on-my-device-after-I-trade-it-in-',
      icloud:
        'https://help.backmarket.com/hc/en-us/articles/360012127479-How-do-I-disconnect-my-iCloud-or-Google-account-',
      google:
        'https://help.backmarket.com/hc/en-us/articles/360012127479-How-do-I-disconnect-my-iCloud-or-Google-account-',
      counterOffer:
        'https://help.backmarket.com/hc/en-us/articles/360010965060',
    },
    csvFaq:
      'https://backmarket.my.site.com/ssc/s/article/Getting-started-with-your-listings',
  },
  [MarketCountryCode.DE]: {
    iban: true,
    bacs: false,
    recyclingFaq: null,
    csvFaq:
      'https://backmarket.my.site.com/ssc/s/article/Getting-started-with-your-listings',
    kyc: {
      firstName: true,
      lastName: true,
      birthDate: true,
      phone: true,
      address: {
        street: true,
        street2: true,
        city: true,
        postalCode: true,
        country: true,
      },
      postalCodeLength: {
        min: 5,
        max: 5,
      },
      identity: {
        required: true,
        list: [
          {
            type: ID_DOCUMENTS.PASSPORT,
            isOneSide: true,
          },
          {
            type: ID_DOCUMENTS.ID_CARD,
            isOneSide: false,
          },
        ],
      },
      bankDetails: {
        required: true,
        type: BANKING_DETAILS.iban,
        fields: ['iban', 'bic'],
      },
      birthdate: {
        required: true,
      },
    },
    tradeinFaq: {
      backup:
        'https://help.backmarket.com/hc/de/articles/360010648979-Was-passiert-mit-den-Daten-auf-meinem-Ger%C3%A4t-nachdem-ich-es-verkauft-habe-',
      icloud:
        'https://help.backmarket.com/hc/de/articles/360012127479-Wie-melde-ich-mich-von-meinem-iCloud-Konto-ab-',
      google:
        'https://help.backmarket.com/hc/de/articles/360012127479-Wie-melde-ich-mich-von-meinem-Google-iCloud-Konto-ab-',
      counterOffer: 'https://help.backmarket.com/hc/de/articles/360010965060',
    },
  },
  [MarketCountryCode.ES]: {
    iban: true,
    bacs: false,
    recyclingFaq: null,
    csvFaq:
      'https://backmarket.my.site.com/ssc/s/article/Getting-started-with-your-listings',
    kyc: {
      firstName: true,
      lastName: true,
      birthDate: true,
      phone: true,
      address: {
        street: true,
        street2: true,
        city: true,
        postalCode: true,
        country: true,
      },
      postalCodeLength: {
        min: 5,
        max: 5,
      },
      identity: {
        required: true,
        list: [
          {
            type: ID_DOCUMENTS.PASSPORT,
            isOneSide: true,
          },
          {
            type: ID_DOCUMENTS.ID_CARD,
            isOneSide: false,
          },
        ],
      },
      bankDetails: {
        required: true,
        type: BANKING_DETAILS.iban,
        fields: ['iban', 'bic'],
      },
      birthdate: {
        required: true,
      },
    },
    tradeinFaq: {
      backup: 'https://help.backmarket.com/hc/es/articles/360010648979',
      icloud: 'https://help.backmarket.com/hc/es/articles/360012127479',
      google: 'https://help.backmarket.com/hc/es/articles/360012127479',
      counterOffer: 'https://help.backmarket.com/hc/es/articles/360021663319',
    },
  },
  [MarketCountryCode.GB]: {
    iban: false,
    bacs: true,
    recyclingFaq: null,
    csvFaq:
      'https://backmarket.my.site.com/ssc/s/article/Getting-started-with-your-listings',
    kyc: {
      firstName: true,
      lastName: true,
      birthDate: true,
      phone: true,
      address: {
        street: true,
        street2: true,
        city: true,
        postalCode: true,
        country: true,
      },
      postalCodeLength: {
        min: 6,
        max: 8,
      },
      identity: {
        required: true,
        list: [
          {
            type: ID_DOCUMENTS.PASSPORT,
            isOneSide: true,
          },
          {
            type: ID_DOCUMENTS.DRIVING_LICENCE,
            isOneSide: false,
          },
        ],
      },
      bankDetails: {
        required: true,
        type: BANKING_DETAILS.bacs,
        fields: ['accountNumber', 'sortCode'],
      },
      birthdate: {
        required: true,
      },
    },
    tradeinFaq: {
      backup: 'https://help.backmarket.com/hc/en-gb/articles/360010648979',
      icloud: 'https://help.backmarket.com/hc/en-gb/articles/360012127479',
      google: 'https://help.backmarket.com/hc/en-gb/articles/360012127479',
      counterOffer:
        'https://help.backmarket.com/hc/fr-fr/articles/360021663319',
    },
  },
}

export function useBuybackConfig(): BuybackData {
  const { market } = useMarketplace()

  return (
    buybackConfig[market.countryCode] || buybackConfig[MarketCountryCode.US]
  )
}
